document.addEventListener("DOMContentLoaded", function () {
  const lazyImages = document.querySelectorAll('img[loading="lazy"]');
  const imageObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const image = entry.target;
        image.src = image.dataset.src;
        image.removeAttribute("loading");
        observer.unobserve(image);
      }
    });
  });

  lazyImages.forEach((image) => {
    imageObserver.observe(image);
  });

  var burger = document.querySelector(".burger");
  var menu = document.querySelector(".header__menu");
  const links = document.querySelectorAll(".header__menu-item");
  burger.addEventListener("click", function () {
    // Проверяем, находится ли уже бургер в активном состоянии
    if (this.classList.contains("active")) {
      // Возвращаем к исходному виду
      this.classList.remove("active");
      menu.classList.remove("active");
      setTimeout(() => {
        this.classList.remove("center");
        menu.style.opacity = 0;
      }, 150); // время должно соответствовать длительности анимации
    } else {
      // Сначала собираем все в центр
      this.classList.add("center");
      menu.classList.add("active");
      setTimeout(() => {
        // Затем поворачиваем в крестик
        this.classList.add("active");
        menu.style.opacity = 1;
      }, 150); // задержка для начала поворота после централизации
    }
  });
  links.forEach((link) => {
    link.addEventListener("click", function () {
      menu.classList.remove("active");
      burger.classList.remove("active");
      setTimeout(() => {
        burger.classList.remove("center");
      }, 150);
    });
  });

  document.addEventListener("scroll", function () {
    const nav = document.querySelector("nav");
    if (window.scrollY >= 50) {
      nav.classList.add("scrolled");
    } else {
      nav.classList.remove("scrolled");
    }
  });

  var swiper = new Swiper(".projects-swiper", {
    slidesPerView: 1.2,
    spaceBetween: 10,
    navigation: {
      nextEl: ".projects-button-next",
      prevEl: ".projects-button-prev",
    },
    pagination: {
      el: ".projects-pagination",
    },
    breakpoints: {
      768: {
        slidesPerView: 1.5,
        spaceBetween: 30,
      },
    },
  });

  var modal = new bootstrap.Modal(document.getElementById("imageModal"));
  var modalImage = document.getElementById("modalImage");

  document.querySelectorAll(".popup-trigger").forEach(function (image) {
    image.addEventListener("click", function () {
      modalImage.src = this.src;
      modal.show();
    });
  });

  var myMap = document.querySelector(".contacts__map iframe");

  myMap.style.width = "100%";
  myMap.style.height = "320px";
  myMap.style.border = "1px solid #C6AA92";
  myMap.style.borderRadius = "10px";

  
});
